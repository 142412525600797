var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.loading,
          expression: "loading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      attrs: { id: "settings_personal_info" }
    },
    [
      _c("inner-page-title", {
        attrs: { title: "Edit profile", action: "Save" },
        on: { actionClicked: _vm.onSaveProfile }
      }),
      _c(
        "div",
        { staticClass: "container", staticStyle: { "max-width": "600px" } },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { sm: 10 } },
                    [
                      _c(
                        "form-item",
                        { attrs: { label: "First name" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "First name" },
                            model: {
                              value: _vm.user.first_name,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "first_name", $$v)
                              },
                              expression: "user.first_name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { sm: 10 } },
                    [
                      _c(
                        "form-item",
                        { attrs: { label: "Last name" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "Last name" },
                            model: {
                              value: _vm.user.last_name,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "last_name", $$v)
                              },
                              expression: "user.last_name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { sm: 4 } },
                    [
                      _c(
                        "form-item",
                        { attrs: { label: "Gender" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "Gender" },
                              model: {
                                value: _vm.user.gender,
                                callback: function($$v) {
                                  _vm.$set(_vm.user, "gender", $$v)
                                },
                                expression: "user.gender"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "Male", value: "male" }
                              }),
                              _c("el-option", {
                                attrs: { label: "Female", value: "female" }
                              }),
                              _c("el-option", {
                                attrs: { label: "Other", value: "other" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "form-item",
                        { attrs: { label: "Email" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "Email address",
                              disabled: ""
                            },
                            model: {
                              value: _vm.user.email,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "email", $$v)
                              },
                              expression: "user.email"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { sm: 14 } },
                    [
                      _c(
                        "form-item",
                        { attrs: { label: "Phone number" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "Phone number",
                              disabled: ""
                            },
                            model: {
                              value: _vm.user.phone_number,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "phone_number", $$v)
                              },
                              expression: "user.phone_number"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { sm: 10 } },
                    [
                      _c(
                        "form-item",
                        { attrs: { label: "Age" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "Age" },
                              model: {
                                value: _vm.user.age,
                                callback: function($$v) {
                                  _vm.$set(_vm.user, "age", $$v)
                                },
                                expression: "user.age"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  value: 22,
                                  label: "Less than 26 years old"
                                }
                              }),
                              _c("el-option", {
                                attrs: {
                                  value: 30,
                                  label: "26 to 35 years old"
                                }
                              }),
                              _c("el-option", {
                                attrs: {
                                  value: 40,
                                  label: "36 to 45 years old"
                                }
                              }),
                              _c("el-option", {
                                attrs: {
                                  value: 50,
                                  label: "46 to 55 years old"
                                }
                              }),
                              _c("el-option", {
                                attrs: {
                                  value: 60,
                                  label: "56 to 65 years old"
                                }
                              }),
                              _c("el-option", {
                                attrs: {
                                  value: 70,
                                  label: "More than 65 years old"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }